import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { createPinia } from 'pinia';
import constants from "./constants";
import VueGtag from "vue-gtag";

import '@/assets/styles/global.css';

const app = createApp(App);

// Utilisation de `provide` pour rendre les constantes disponibles globalement
app.provide('constants', constants);

// Ajouter les plugins à l'instance de l'application
app.use(router)
   .use(vuetify)
   .use(createPinia())
   .use(VueGtag, {
      config: { id: "G-94F5LZXEWH" }
   }, router);

// Monter l'application
app.mount("#app");
